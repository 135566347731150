import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

import { ElemItem, Props as ElemProps } from './item'

export interface Props {
  pretitle?: string
  title?: string
  subtitle?: string
  elems: ElemProps[]
}

export const ElemsList = memo(function ElemsList({
  pretitle,
  title,
  subtitle,
  elems,
}: Props) {
  return (
    <Container>
      <WrapperText>
        {pretitle ? (
          <FadeInUp>
            <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
          </FadeInUp>
        ) : null}
        {title ? <Title text={title} /> : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          </FadeInUp>
        ) : null}
      </WrapperText>

      <WrapperElems>
        {elems.map((elem, index) => (
          <ElemItem {...elem} index={index} key={index} />
        ))}
      </WrapperElems>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 6.66vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const WrapperText = styled.div`
  margin-bottom: 3.66vw;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 3vw 0 0 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    margin-top: 20px;
    font-size: 28px;
  }
`

const Subtitle = styled.h4`
  font-size: 1.45vw;
  font-weight: 300;
  margin-top: 0.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};

  @media (max-width: 991px) {
    font-size: 20px;
    margin-top: 10px;
  }
`

const WrapperElems = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: -3vw;
  margin-right: -3vw;

  @media (max-width: 991px) {
    display: block;
    margin: 0;
  }
`
