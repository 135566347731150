import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { FeatureProps, FeaturesList } from 'app/components/Common/FeaturesList'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Lines } from 'app/components/Common/Lines'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title?: string
  subtitle?: string
  description?: string
  featuresTitle?: string
  features?: FeatureProps[]
  address?: string
  googleMapsURL?: string
}

export const IntroPosition = memo(function IntroPosition({
  image,
  title,
  subtitle,
  description,
  address,
  googleMapsURL,
  featuresTitle,
  features,
}: Props) {
  return (
    <Container>
      <Lines />
      <WrapperCenter>
        <TextCenter>
          {title ? <TitleCenter text={title} /> : null}
          {subtitle ? (
            <FadeInUp>
              <SubtitleCenter dangerouslySetInnerHTML={{ __html: subtitle }} />
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <DescriptionCenter
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </FadeInUp>
          ) : null}
        </TextCenter>
      </WrapperCenter>

      <WrapperImage>
        <FadeInUp>
          <StyledImage {...image} />
        </FadeInUp>
        <TextImage>
          <StructureName>Hotel St. Mauritius</StructureName>
          {address ? (
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          ) : null}
          <StyledButton
            variant="full-dark"
            target="_blank"
            URL={googleMapsURL}
            label="dove siamo"
          />
        </TextImage>
      </WrapperImage>

      <WrapperFeatures>
        <WrapperFeaturesTitle>
          <FeaturesTitle text={featuresTitle} />
        </WrapperFeaturesTitle>
        {features ? (
          <WrapperFeaturesList>
            <FeaturesList features={features} />
          </WrapperFeaturesList>
        ) : null}
      </WrapperFeatures>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.6vw;
  position: relative;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const WrapperCenter = styled.div`
  text-align: center;
  width: 40vw;
  min-width: 600px;
  margin: 0 auto;

  @media (max-width: 1199px) {
    width: 100%;
    min-width: 0;
  }
`

const TitleCenter = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 2vw 0 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 10px 0;
  }
`

const TextCenter = styled.div`
  text-align: center;
`

const DescriptionCenter = styled.div`
  p {
    font-size: 17px;
    line-height: 26px;
  }
`

const SubtitleCenter = styled.h4`
  font-size: 1.45vw;
  font-weight: 300;
  margin-top: 0.5vw;
  margin-bottom: 3vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};

  @media (max-width: 991px) {
    font-size: 20px;
  }
`

const WrapperImage = styled.div`
  margin-top: 4vw;
  position: relative;
`
const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 1199px) {
    height: 100vw;
    display: block;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
`
const StructureName = styled.div`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const TextImage = styled.div`
  position: absolute;
  bottom: 5vw;
  width: 100%;
  text-align: center;

  @media (max-width: 991px) {
    bottom: 30px;
    padding: 0 20px;
  }
`

const Address = styled.div`
  font-size: 1.45vw;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    font-size: 20px;
    margin: 20px auto;
  }
`

const StyledButton = styled(Button)`
  margin: 2vw auto 0 auto;
`

const WrapperFeatures = styled.div`
  display: flex;
  margin-top: 4vw;

  @media (max-width: 991px) {
    display: block;
    margin-top: 30px;
  }
`
const WrapperFeaturesTitle = styled.div`
  width: 28vw;
  position: relative;
  font-size: 2.2vw;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};

  padding: 0 2.7vw;
  padding-bottom: 2.7vw;

  &::before {
    top: 0;
    left: 0;
    content: ' ';
    width: 1px;
    height: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    display: block;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 0 30px;
    margin-bottom: 30px;
    font-size: 28px;
  }
`

const FeaturesTitle = styled(AnimatedTitle)``

const WrapperFeaturesList = styled.div`
  * {
    align-self: start !important;
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
`
