import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title?: string
  subtitle?: string
  description?: string
  ctaList?: ButtonProps[]
  index?: number
}

export const ElemItem = memo(function ElemItem({
  image,
  title,
  subtitle,
  description,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <StyledImage {...image} />
        <Header>
          {title ? (
            <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
          ) : null}
          {subtitle ? (
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }}></Subtitle>
          ) : null}
        </Header>
        {description ? (
          <Text dangerouslySetInnerHTML={{ __html: description }}></Text>
        ) : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.article`
  position: relative;
  width: 50%;
  padding: 0 3vw;
  margin: 2vw 0;

  &:nth-of-type(even) {
    transform: translateY(-16vw);
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin: 0 0 40px 0;
    transform: none !important;
  }
`
const Header = styled.header`
  margin-top: 1vw;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const Title = styled.h3`
  font-weight: 300;
  font-size: 1.45vw;
  line-height: 2.2vw;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;

  @media (max-width: 991px) {
    font-size: 20px;
  }
`

const Subtitle = styled.div`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 0.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
    margin-top: 20px;
  }
`

const Text = styled.div`
  margin-top: 1.5vw;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const StyledImage = styled(Image)`
  display: block;
  margin-top: 1.8vw;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 991px) {
    margin-top: 15px;
  }
`
